import React from 'react';
import EmptyState from '@atlaskit/empty-state';
import { Box, xcss } from '@atlaskit/primitives';
import Link from '@atlaskit/link';
import { useIntl } from '@atlassian/jira-intl';
import LockImage from '../assets/lock.svg';
import { messages } from './messages.tsx';

export const NoAccessErrorPage = () => {
	const { formatMessage } = useIntl();
	return (
		<Box xcss={containerStyles}>
			<EmptyState
				imageUrl={LockImage}
				header={formatMessage(messages.errorTitle)}
				description={formatMessage(messages.errorDescription)}
				tertiaryAction={
					<Link href="https://home.atlassian.com">{formatMessage(messages.goToHomeLinkLabel)}</Link>
				}
			/>
		</Box>
	);
};
const containerStyles = xcss({
	display: 'flex',
	height: '100%',
	placeItems: 'center',
});
