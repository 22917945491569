import React, { type ReactElement } from 'react';
import { ContextualAnalyticsData, SCREEN } from '@atlassian/jira-product-analytics-bridge';
import { ServiceDeskAnalyticsContext } from '@atlassian/jira-servicedesk-analytics/src/ui/servicedesk-analytics-context/index.tsx';
import {
	type AppName,
	toPackageName,
} from '@atlassian/jira-servicedesk-common/src/utils/app-names/index.tsx';

import { SubProductUpdater } from '@atlassian/jira-spa-apps-common/src/analytics-sub-product/sub-product-updater/index.tsx';
import { AuthenticationCheck } from '@atlassian/jira-spa-apps-common/src/interceptors/authentication/index.tsx';
import { AsyncCmdbUpsellPage } from '@atlassian/jira-cmdb-upsell-page/src/async.tsx';
import {
	GenericPermissionErrorPageAsync,
	NotFoundErrorPageAsync,
} from '@atlassian/jira-error-pages/src/async.tsx';
import { isCMDBEnabledInEnvironment } from '@atlassian/jira-servicedesk-cmdb-fedramp/src/index.tsx';
import {
	useInsightVisibility,
	useWorkspaceContext,
} from '@atlassian/jira-servicedesk-insight-common-workspace-context/src/controllers/workspace-context/index.tsx';
import {
	getUserJsmLicenceState,
	isLicensedPremiumUser,
} from '@atlassian/jira-servicedesk-insight-common-workspace-context/src/services/resources/get-data/index.tsx';
import type { WorkspaceId } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/shared-types/index.tsx';
import {
	PageStyle,
	type PageWrapper,
} from '@atlassian/jira-servicedesk-spa-commons/src/common/utils/page-style/index.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { CmdbArchitectureContainer } from '@atlassian/jira-servicedesk-cmdb-architecture/src/ui/index.tsx';
import { Redirect } from '@atlassian/react-resource-router';
import { NoAccessErrorPage } from './no-access-error-page/index.tsx';

type LegacyProps = {
	children: (arg1: { workspaceId: WorkspaceId }) => ReactElement;
};

type Props = {
	appName: AppName;
	children: (arg1: { workspaceId: WorkspaceId }) => ReactElement;
	pageStyle?: PageWrapper;
};

export const AssetsAppBase = ({ appName, children, pageStyle = PageStyle }: Props) => {
	return (
		<AuthenticationCheck>
			<SubProductUpdater subProduct="serviceDesk" />
			<ServiceDeskAnalyticsContext>
				<ContextualAnalyticsData sourceName={toPackageName(appName)} sourceType={SCREEN}>
					<AssetsAppBaseInternal pageStyle={pageStyle}>{children}</AssetsAppBaseInternal>
				</ContextualAnalyticsData>
			</ServiceDeskAnalyticsContext>
		</AuthenticationCheck>
	);
};

export const AssetsAppBaseInternal = ({
	children,
	pageStyle: PageWrapper,
}: {
	children: (arg1: { workspaceId: WorkspaceId }) => ReactElement;
	pageStyle: PageWrapper;
}) => {
	const { workspaceId, error, loading } = useWorkspaceContext();
	const hasInsightPermission = useInsightVisibility();
	const tenantContext = useTenantContext();
	const licenceState = getUserJsmLicenceState(tenantContext);

	if (!isCMDBEnabledInEnvironment()) {
		return <NotFoundErrorPageAsync />;
	}

	if (licenceState.siteLicence === 'unlicensed') {
		return <Redirect to="/" />;
	}

	if (
		licenceState.siteLicence === 'licensed' &&
		licenceState.siteIsPremium &&
		!licenceState.userLicensed
	) {
		return <NoAccessErrorPage />;
	}

	if (
		licenceState.siteLicence === 'licensed' &&
		!licenceState.siteIsPremium &&
		licenceState.userLicensed
	) {
		return <AsyncCmdbUpsellPage />;
	}

	if (workspaceId == null) {
		return !loading && !error ? <NotFoundErrorPageAsync /> : null;
	}

	if (hasInsightPermission === false || error) {
		return <GenericPermissionErrorPageAsync />;
	}

	return (
		<PageWrapper>
			<CmdbArchitectureContainer workspaceId={workspaceId}>
				{children({ workspaceId })}
			</CmdbArchitectureContainer>
		</PageWrapper>
	);
};

// Clean this up while cleaning assets_as_an_app_v2 ff
export const InsightAppBaseV2 = ({ children }: LegacyProps) => {
	const { workspaceId, error, loading } = useWorkspaceContext();
	const hasInsightPermission = useInsightVisibility();
	const tenantContext = useTenantContext();

	if (!isCMDBEnabledInEnvironment()) {
		return <NotFoundErrorPageAsync />;
	}

	if (!isLicensedPremiumUser(tenantContext)) {
		return <AsyncCmdbUpsellPage />;
	}

	if (workspaceId == null) {
		return !loading && !error ? <NotFoundErrorPageAsync /> : null;
	}

	if (hasInsightPermission === false || error) {
		return <GenericPermissionErrorPageAsync />;
	}

	return (
		<PageStyle>
			<CmdbArchitectureContainer workspaceId={workspaceId}>
				{children({ workspaceId })}
			</CmdbArchitectureContainer>
		</PageStyle>
	);
};
