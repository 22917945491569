/** @jsx jsx */
import { memo } from 'react';
import { styled, jsx } from '@compiled/react';
import Avatar, { Skeleton, AVATAR_SIZES, type SizeType } from '@atlaskit/avatar';
import type { FileIdentifier, MediaStoreGetFileImageParams } from '@atlaskit/media-client';
import type { ClientBasedAuth } from '@atlaskit/media-core';
import { MediaImage } from '@atlaskit/media-image';
import { token } from '@atlaskit/tokens';
import { fireCmdbErrorAnalytics } from '@atlassian/jira-servicedesk-cmdb-error-handling/src/fireCmdbErrorAnalytics.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { CmdbMediaClientConfig } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/cmdb-object.tsx';

export type InsightIconProps = {
	mediaClientConfig?: CmdbMediaClientConfig;
	iconUrl: string;
	size: SizeType;
	label: string;
};

export const hasClientAuth = (mediaClientConfig: CmdbMediaClientConfig): boolean =>
	Boolean(mediaClientConfig.mediaBaseUrl) &&
	Boolean(mediaClientConfig.mediaJwtToken) &&
	Boolean(mediaClientConfig.clientId) &&
	Boolean(mediaClientConfig.fileId);

const DefaultIcon = ({ size, label }: { size: SizeType; label: string }) => (
	<Avatar appearance="square" size={size} name={label} />
);

export const getCMDBClientBasedAuth = (
	clientAuth: CmdbMediaClientConfig,
): Promise<ClientBasedAuth> =>
	Promise.resolve({
		clientId: clientAuth.clientId,
		token: clientAuth.mediaJwtToken,
		baseUrl: clientAuth.mediaBaseUrl,
	});

export const InsightIconOld = (props: InsightIconProps) => {
	const { iconUrl, size, label, mediaClientConfig } = props;

	if (!mediaClientConfig || hasClientAuth(mediaClientConfig) === false) {
		if (iconUrl) {
			return <Avatar src={iconUrl} appearance="square" size={size} name={label} />;
		}

		return <DefaultIcon size={size} label={label} />;
	}

	const fileIdentifier: FileIdentifier = {
		id: mediaClientConfig.fileId,
		mediaItemType: 'file',
	};
	const imageSize: MediaStoreGetFileImageParams = {
		width: AVATAR_SIZES[size],
		height: AVATAR_SIZES[size],
	};

	return (
		<MediaImage
			identifier={fileIdentifier}
			mediaClientConfig={{
				authProvider: () => getCMDBClientBasedAuth(mediaClientConfig),
			}}
			apiConfig={imageSize}
		>
			{({ loading, error, data }) => {
				if (loading) {
					return <Skeleton appearance="square" size={size} />;
				}

				if (error) {
					fireCmdbErrorAnalytics({
						// @ts-expect-error - TS2322 - Type 'true' is not assignable to type 'Error | undefined'.
						error,
						meta: {
							id: 'insightIcon',
							packageName: 'jiraServicedeskInsightIcon',
							teamName: 'falcons',
						},
					});
					return <DefaultIcon size={size} label={label} />;
				}

				if (!data) {
					return <DefaultIcon size={size} label={label} />;
				}

				return <Avatar src={data.src} appearance="square" size={size} name={label} />;
			}}
		</MediaImage>
	);
};

export const InsightIconNew = (props: InsightIconProps) => {
	const { iconUrl, size, label, mediaClientConfig } = props;
	const sizeInPx = AVATAR_SIZES[size];

	const renderAvatar = (src: string | undefined) => (
		<img loading="lazy" src={src} width={sizeInPx} height={sizeInPx} alt="" />
	);

	if (!mediaClientConfig || hasClientAuth(mediaClientConfig) === false) {
		if (iconUrl) {
			return renderAvatar(iconUrl);
		}
		return <DefaultIcon size={size} label={label} />;
	}

	const fileIdentifier: FileIdentifier = {
		id: mediaClientConfig.fileId,
		mediaItemType: 'file',
	};
	const imageSize: MediaStoreGetFileImageParams = {
		width: AVATAR_SIZES[size],
		height: AVATAR_SIZES[size],
	};

	return (
		<MediaImage
			identifier={fileIdentifier}
			mediaClientConfig={{
				authProvider: () => getCMDBClientBasedAuth(mediaClientConfig),
			}}
			apiConfig={imageSize}
		>
			{({ loading, error, data }) => {
				if (loading) {
					return <Skeleton appearance="square" size={size} />;
				}

				if (error) {
					fireCmdbErrorAnalytics({
						// @ts-expect-error - TS2322 - Type 'true' is not assignable to type 'Error | undefined'.
						error,
						meta: {
							id: 'insightIcon',
							packageName: 'jiraServicedeskInsightIcon',
							teamName: 'falcons',
						},
					});
					return <DefaultIcon size={size} label={label} />;
				}

				if (!data) {
					return <DefaultIcon size={size} label={label} />;
				}

				return renderAvatar(data.src);
			}}
		</MediaImage>
	);
};

export const InsightIcon = componentWithFG(
	'jsdcloud-14092-lazy-load-img',
	InsightIconNew,
	InsightIconOld,
);

export const MemoizedInsightIcon = memo<InsightIconProps>(InsightIcon);

/**
 *
 * A wrapper around MemoizedInsightIcon that includes style overrides to prevent
 * extra unwanted spacing around the icon.
 *
 * Context: The default InsightIcon component uses an atlaskit avatar component
 * behind the scenes which includes some default styling (namely, a 2px border)
 * which causes the icon to be 4px wider and taller than expected. This causes
 * some trouble when trying to render the icon with exact dimension.
 */
export const UnstyledInsightIcon = (props: InsightIconProps) => (
	<UnstyledInsightIconWrapper isFGEnabled={fg('jsdcloud-14092-lazy-load-img')}>
		<MemoizedInsightIcon {...props} />
	</UnstyledInsightIconWrapper>
);

type InsightIconWrapperProps = {
	isFGEnabled: boolean;
};

/**
 * The reason for calling !important on display block is to ovveride the inherited
 * CSS from Avatar atlaskit component in order to centre the icon within the reference
 * object container.
 */
// Double check the shadow !
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const UnstyledInsightIconWrapper = styled.div<InsightIconWrapperProps>(
	{
		marginTop: token('space.negative.025'),
		marginRight: token('space.negative.025'),
		marginBottom: token('space.negative.025'),
		marginLeft: token('space.negative.025'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'> div': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
			display: 'block !important',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			'> span': {
				boxShadow: 'none',
			},
		},
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	({ isFGEnabled }) => isFGEnabled && { display: 'flex' },
);
