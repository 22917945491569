import React, { type ReactNode, useRef, useEffect } from 'react';
import { styled } from '@compiled/react';
import { Box, xcss } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { SchemaIcon } from '@atlassian/jira-servicedesk-insight-glyphs/src/index.tsx';
import { MemoizedInsightIcon } from '@atlassian/jira-servicedesk-insight-icon/src/ui/index.tsx';
import { Link } from '@atlassian/react-resource-router';
import { fg } from '@atlassian/jira-feature-gating';
import type { Props } from './types.tsx';

export const InsightRapidSearchDropdownOption = ({
	url,
	mediaClientConfig,
	label,
	iconUrl,
	schemaName,
	isSelected,
	onClick,
	objectKey,
}: Props) => {
	const selectedElementReference = useRef<HTMLLIElement>(null);

	useEffect(() => {
		if (isSelected && selectedElementReference.current) {
			selectedElementReference.current.scrollIntoView?.({ block: 'center' });
		}
	}, [isSelected]);

	return (
		<li ref={selectedElementReference}>
			<Row
				isAssetsAppEnabled={fg('assets_as_an_app_v2')}
				isSelected={isSelected}
				to={url}
				onClick={onClick}
				/* @ts-expect-error - TS2322 - Type 'string' is not assignable to type 'number | undefined'. */
				tabIndex="-1"
				data-testid="servicedesk-insight-rapid-search-bar.ui.popup-content.dropdown-container.dropdown-option.dropdown-option"
			>
				<ObjectContainer>
					<MemoizedInsightIcon
						mediaClientConfig={mediaClientConfig}
						iconUrl={iconUrl}
						label={label}
						size="small"
					/>
					{objectKey !== undefined && <ObjectKey>{objectKey}</ObjectKey>}
					<Text>{label}</Text>
				</ObjectContainer>
				<SchemaContainer>
					<SchemaIcon label={schemaName} size="medium" />
					<Text>{schemaName}</Text>
				</SchemaContainer>
			</Row>
		</li>
	);
};

const Text = ({ children }: { children?: ReactNode }) => <Box xcss={textStyles}>{children}</Box>;

const ObjectContainer = ({ children }: { children?: ReactNode }) => (
	<Box xcss={objectContainerStyles}>{children}</Box>
);

const ObjectKey = ({ children }: { children?: ReactNode }) => (
	<Box xcss={objectKeyStyles}>{children}</Box>
);

const SchemaContainer = ({ children }: { children?: ReactNode }) => (
	<Box xcss={schemaContainerStyles}>{children}</Box>
);

const schemaContainerStyles = xcss({
	display: 'flex',
	alignItems: 'center',
	gap: 'space.100',
	color: 'color.text.subtlest',
	font: token('font.body.UNSAFE_small'),
	padding: 'space.100',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Row = styled(Link)<{ isSelected: boolean; isAssetsAppEnabled: boolean }>(
	{
		display: 'flex',
		alignItems: 'center',
		cursor: 'pointer',

		color: token('color.text'),
		paddingTop: token('space.100'),
		paddingRight: token('space.100'),
		paddingBottom: token('space.100'),
		paddingLeft: token('space.100'),
		maxWidth: '100%',
		'&:hover': {
			color: token('color.text'),
			textDecoration: 'none',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
			backgroundColor: (props) =>
				props.isAssetsAppEnabled
					? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values
						token('color.background.selected.hovered', colors.B200)
					: // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
						token('elevation.surface.raised', colors.N30),
		},
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(props) =>
		props.isSelected && {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			backgroundColor: token('color.background.selected', colors.N30),
		},
);

export const visibleForTesting = {
	ObjectKey,
};

const textStyles = xcss({
	textOverflow: 'ellipsis',
	overflow: 'hidden',
	whiteSpace: 'nowrap',
});

const objectContainerStyles = xcss({
	display: 'flex',
	alignItems: 'center',
	flexGrow: 1,
	gap: 'space.100',
	overflow: 'auto',
});

const objectKeyStyles = xcss({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
	...textStyles,
	paddingRight: 'space.100',
	flexShrink: 0,
});
