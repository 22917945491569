import type { CmdbMediaClientConfig } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/cmdb-object.tsx';
import type { CompletedObjectTask } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/task.tsx';

export const getCmdbAsyncActionsResultsModalEntrypointProps = ({
	taskId,
}: CompletedObjectTask) => ({
	taskId,
});

export const createDownloadFromMediaUrl = ({
	mediaBaseUrl,
	fileId,
	mediaJwtToken,
	clientId,
}: CmdbMediaClientConfig) =>
	`${mediaBaseUrl}/file/${fileId}/binary?token=${mediaJwtToken}&client=${clientId}&dl=true`;
