import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	errorTitle: {
		id: 'spa-assets-app-base.no-access-error-page.error-title',
		defaultMessage: 'You need permission to access this page',
		description: 'Error page title for when users do not have permission to access the page',
	},
	errorDescription: {
		id: 'spa-assets-app-base.no-access-error-page.error-description',
		defaultMessage: 'If you think you should have access, contact your admin.',
		description:
			'The description for the error page for when users do not have permission to access the page',
	},
	goToHomeLinkLabel: {
		id: 'spa-assets-app-base.no-access-error-page.go-to-home-link-label',
		defaultMessage: 'Go to Atlassian Home',
		description: 'The label for the link that navigates the user to the Atlassian home page',
	},
});
