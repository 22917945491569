import React from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import {
	fireOperationalAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { useIsNewCmdbArchitecture } from '@atlassian/jira-servicedesk-cmdb-architecture/src/controllers/index.tsx';
import { CmdbAsyncActionsContainer } from '@atlassian/jira-servicedesk-cmdb-async-actions/src/ui/index.tsx';
import { APP_NAMES } from '@atlassian/jira-servicedesk-common/src/utils/app-names/index.tsx';
import { InsightGlobalConfigContainer } from '@atlassian/jira-servicedesk-insight-global-configuration-store/src/services/index.tsx';
import { InsightLandingPageContainer } from '@atlassian/jira-servicedesk-insight-landing-page-store/src/controllers/store/index.tsx';
import { LandingPage as InsightLandingPage } from '@atlassian/jira-servicedesk-insight-landing-page/src/ui/index.tsx';
import {
	getLandingPageUrl,
	getRootPath,
	isInsightRoute,
} from '@atlassian/jira-servicedesk-insight-urls/src/index.tsx';
import ServiceDeskAppBase from '@atlassian/jira-servicedesk-spa-commons/src/common/utils/app-base/index.tsx';
import {
	InsightAppBaseV2,
	AssetsAppBase,
} from '@atlassian/jira-spa-assets-app-base/src/ui/index.tsx';
import { Redirect, useRouter } from '@atlassian/react-resource-router';

export const CmdbLandingPageView = () => {
	if (fg('assets_as_an_app_v2')) {
		return <AssetsAppCmdbLandingPageView />;
	}

	return <LegacyCmdbLandingPageView />;
};

// Update name when cleaning up fg 'assets_rearch_migration'
export const ConditionalInsightLandingPage = ({ workspaceId }: { workspaceId: string }) => {
	const isNewCmdbArchitecture = useIsNewCmdbArchitecture();

	return isNewCmdbArchitecture ? (
		<CmdbAsyncActionsContainer workspaceId={workspaceId}>
			<InsightLandingPage />
		</CmdbAsyncActionsContainer>
	) : (
		<InsightLandingPage />
	);
};

export const AssetsAppCmdbLandingPageView = () => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	return (
		<AssetsAppBase appName={APP_NAMES.INSIGHT_LANDING_PAGE}>
			{({ workspaceId }) => (
				<InsightLandingPageContainer
					workspaceId={workspaceId}
					createAnalyticsEvent={createAnalyticsEvent}
				>
					<ConditionalInsightLandingPage workspaceId={workspaceId} />
				</InsightLandingPageContainer>
			)}
		</AssetsAppBase>
	);
};

export const LegacyCmdbLandingPageView = () => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	return (
		<ServiceDeskAppBase appName={APP_NAMES.INSIGHT_LANDING_PAGE}>
			<InsightAppBaseV2>
				{({ workspaceId }) => (
					<InsightGlobalConfigContainer
						isGlobal
						workspaceId={workspaceId}
						createAnalyticsEvent={createAnalyticsEvent}
					>
						<InsightLandingPageContainer
							workspaceId={workspaceId}
							createAnalyticsEvent={createAnalyticsEvent}
						>
							<ConditionalInsightLandingPage workspaceId={workspaceId} />
						</InsightLandingPageContainer>
					</InsightGlobalConfigContainer>
				)}
			</InsightAppBaseV2>
		</ServiceDeskAppBase>
	);
};

export const ConditionalRedirectOrRouteView = () => {
	const [{ location, route }] = useRouter();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	if (isInsightRoute(location.pathname)) {
		fireOperationalAnalytics(
			createAnalyticsEvent({ action: 'redirected', actionSubject: 'routeView' }),
			'jsmCmdbAssetsRouteRedirect landing-page',
			{ redirectedFrom: route.path, redirectedTo: getRootPath() },
		);

		return <Redirect to={getLandingPageUrl()} />;
	}

	return <CmdbLandingPageView />;
};

export default ConditionalRedirectOrRouteView;
